import type { ImagesMap } from "@web-solutions/core/interfaces/images";

import ALERT_1X from './alert@1x.webp'
import ALERT_2X from './alert@2x.webp'
import ALERT_3X from './alert@3x.webp'

export const IMAGES: ImagesMap = {
  ALERT: {
    src: ALERT_1X,
    srcSet: `${ALERT_1X}, ${ALERT_2X} 2x, ${ALERT_3X} 3x`,
  }
};