import _merge from 'lodash/merge';

const resources = {
  de: {
    translation: require('./de/index_account_management.json'),
  },
  en: {
    translation: require('./en/index_account_management.json'),
  },
  'es-mx': {
    translation: require('./es-MX/index_account_management.json'),
  },
  es: {
    translation: require('./es-ES/index_account_management.json'),
  },
  fr: {
    translation: require('./fr/index_account_management.json'),
  },
  'pt-br': {
    translation: require('./pt-BR/index_account_management.json'),
  },
  pt: {
    translation: require('./pt-PT/index_account_management.json'),
  },
  ja: {
    translation: require('./ja/index_account_management.json'),
  },
  ar: {
    translation: require('./ar/index_account_management.json'),
  },
}

const noProofReadResources = {
  de: {
    translation: require('./de/no_proofread.json'),
  },
  en: {
    translation: require('./en/no_proofread.json'),
  },
  es: {
    translation: require('./es-ES/no_proofread.json'),
  },
  fr: {
    translation: require('./fr/no_proofread.json'),
  },
  'pt-br': {
    translation: require('./pt-BR/no_proofread.json'),
  },
  pt: {
    translation: require('./pt-PT/no_proofread.json'),
  },
  ja: {
    translation: require('./ja/no_proofread.json'),
  },
  ar: {
    translation: require('./ar/no_proofread.json'),
  },
}

export const resultResources = _merge({}, resources, noProofReadResources);