import type { SubtruckExternalEventConfig } from "..";

export const getDefaultExternalEventsConfig = ({ value, currency }: Record<'value' | 'currency', any>): SubtruckExternalEventConfig[] => ([
  {
    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "google_analytics",
    "name": "purchase",
    "value": value,
    "currency": currency
  },
  {
    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "google_analytics",
    "name": "subtruck_intro",
    "value": value,
    "currency": currency
  },
  {

    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "facebook_conversion_api",
    "name": "Purchase",
    "additional_params": {
      "predicted_ltv": value
    },
    "value": value,
    "currency": currency
  },
  {
    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "facebook_conversion_api",
    "name": "StartTrial",
    "additional_params": {
      "predicted_ltv": value,
    },
    "value": value,
    "currency": currency
  },
  {
    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "facebook_conversion_api",
    "name": "subtruck_intro",
    "additional_params": {
      "predicted_ltv": value,
    },
    "value": value,
    "currency": currency
  },
  {
    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "pinterest_conversion_api",
    "name": "checkout",
    "value": value,
    "currency": currency
  },
  {
    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "tik_tok_conversion_api",
    "name": "CompletePayment",
    "value": value,
    "currency": currency
  },
  {
    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "snapchat_conversion_api",
    "name": "SUBSCRIBE",
    "additional_params": {
      "predicted_ltv": value,
    },
    "value": value,
    "currency": currency
  },
  {

    "subscription_event_status": "subscribe_with_introductory_period",
    "service": "snapchat_conversion_api",
    "name": "PURCHASE",
    "value": value,
    "currency": currency
  }
])