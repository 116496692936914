export const DARK_FORM_STYLES = {
  LABELS: {
    color: '#ffffff',
    'font-size': '0.87rem !important',
    'font-weight': '400',
    'line-height': '1.25rem',
  },
  INPUTS: {
    'border-color': 'rgba(184, 185, 195, 0.3)',
    height: '3em',
    background: '#2B5567',
    'border-radius': '10px',
    color: 'rgba(255, 255, 255, 0.8)',
    '::placeholder': {
      'color': 'rgba(255, 255, 255, 0.3)',
    }
  },
  NOT_EMPTY_LABELS: {
    'color': '#ffffff',
  }
}

export const SUBMIT_BUTTON_COLOR = '#16786c';
export const SUBMIT_BUTTON_COLOR_LIGHT_THEME = '#4651EF';
export const SUBMIT_BUTTON_COLOR_DARK_THEME = '#5671FF';
export const SUBMIT_BUTTON_COLOR_MONOCHROME = '';

export const SUBMIT_BUTTON_BORDER_RADIUS_MONOCHROME = '';
export const SUBMIT_BUTTON_BORDER_RADIUS_LIGHT_THEME = '';
export const SUBMIT_BUTTON_BORDER_RADIUS_DARK_THEME = '';
export const SUBMIT_BUTTON_BORDER_RADIUS = '';
