export const UPSALES_NAMES = {
  COMPATIBILITY: 'COMPATIBILITY',
  BIRTH_CHART: 'BIRTH_CHART',
  NUMEROLOGY_ANALYSIS: 'NUMEROLOGY_ANALYSIS',
  GUIDES: 'GUIDES',
  ASTROLOGERS_SINGLE_PACK: 'ASTROLOGERS_SINGLE_PACK',
  ASTROLOGERS_PACKS: 'ASTROLOGERS_PACKS',
  COMPATIBILITY_BIRTH_CHART_ULTRA: 'COMPATIBILITY_BIRTH_CHART_ULTRA',
  COMPATIBILITY_HARDCOVER: 'COMPATIBILITY_HARDCOVER',
} as const;

export const SUBSCRIPTION_MAIN_NAMES = {
  PRESUMMARY: 'PRESUMMARY',
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  UPSALES_BASKET: 'UPSALES_BASKET',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
} as const;

export const SUBSCRIPTION_NAMES = {
  ...SUBSCRIPTION_MAIN_NAMES,
  ...UPSALES_NAMES
} as const;

export const ADDITIONAL_SCREENS_NAMES = {
  MY_ACCOUNT: 'MY_ACCOUNT',
} as const;


type SubscriptionNamesKeys = keyof typeof SUBSCRIPTION_MAIN_NAMES | keyof typeof UPSALES_NAMES;
export type AfterPurchase = Exclude<SubscriptionNamesKeys, "PRESUMMARY" | "MAIN" | "SUCCESS">
export type AfterPurchaseStep = AfterPurchase;
export type AfterPurchaseRoutes = Array<AfterPurchase>;

const QUIZ_NAMES_GENERAL = {
  CHAT_FLOW: 'CHAT_FLOW',
  CHAT_FLOW_BRANCHING: 'CHAT_FLOW_BRANCHING',
  QUIZ_WELCOME: 'QUIZ_WELCOME',
  QUIZ_WELCOME_ADVISOR: 'QUIZ_WELCOME_ADVISOR',
  QUIZ_WELCOME_ASTROLOGER: 'QUIZ_WELCOME_ASTROLOGER',
  QUIZ_WELCOME_POPULAR_QUESTIONS: 'QUIZ_WELCOME_POPULAR_QUESTIONS',
  QUIZ_BIRHT_CHART_WELCOME: 'QUIZ_BIRHT_CHART_WELCOME',
  QUIZ_RISING_SIGN_WELCOME: 'QUIZ_RISING_SIGN_WELCOME',
  QUIZ_TAROT_CARDS_WELCOME: 'QUIZ_TAROT_CARDS_WELCOME',
  QUIZ_COMPATIBILITY_WELCOME: 'QUIZ_COMPATIBILITY_WELCOME',
  QUIZ_PALMS_SECRETS_WELCOME: 'QUIZ_PALMS_SECRETS_WELCOME',
  QUIZ_NUMEROLOGY_SECRETS_WELCOME: 'QUIZ_NUMEROLOGY_SECRETS_WELCOME',
  QUIZ_HUMAN_DESIGN_SECRETS_WELCOME: 'QUIZ_HUMAN_DESIGN_SECRETS_WELCOME',
  ZODIAC_WHEEL_WELCOME: 'ZODIAC_WHEEL_WELCOME',
  COMMON_GENDER: 'COMMON_GENDER',
  COMMON_GENDER_PROTECT_BADGES: 'COMMON_GENDER_PROTECT_BADGES',
  GENDER_PERSON_CARDS: 'GENDER_PERSON_CARDS',
  WARMUP_LOADER: 'WARMUP_LOADER',
  QUIZ_MAGIC: 'QUIZ_MAGIC',
  QUIZ_REPORT_LOADER: 'QUIZ_REPORT_LOADER',
  QUIZ_ADVISORS_LOADER: 'QUIZ_ADVISORS_LOADER',
  QUIZ_BIRTHDAY: 'QUIZ_BIRTHDAY',
  QUIZ_BIRTHDAY_WITH_SELECTS: 'QUIZ_BIRTHDAY_WITH_SELECTS',
  QUIZ_BIRTHPLACE: 'QUIZ_BIRTHPLACE',
  QUIZ_TAROT: 'QUIZ_TAROT',
  QUIZ_BIRTHTIME: 'QUIZ_BIRTHTIME',
  QUIZ_TYPES_ESOTERICISM: 'QUIZ_TYPES_ESOTERICISM',
  QUIZ_BIRTHTIME_WITH_SELECTS: 'QUIZ_BIRTHTIME_WITH_SELECTS',
  QUIZ_TRANSITION_SUN: 'QUIZ_TRANSITION_SUN',
  QUIZ_TRANSITION_ASCENDANT: 'QUIZ_TRANSITION_ASCENDANT',
  QUIZ_TRANSITION_HOROSCOPE: 'QUIZ_TRANSITION_HOROSCOPE',
  QUIZ_TRANSITION_BIRTH_CHART: 'QUIZ_TRANSITION_BIRTH_CHART',
  QUIZ_TRANSITION_REVIEWS: 'QUIZ_TRANSITION_REVIEWS',
  QUIZ_TRANSITION_LOADER_ANIMATION: 'QUIZ_TRANSITION_LOADER_ANIMATION',
  QUIZ_PALM_READING: 'QUIZ_PALM_READING',
  QUIZ_TRIAL: 'QUIZ_TRIAL',
  QUIZ_PERFECT_DATE: 'QUIZ_PERFECT_DATE',
  QUIZ_RELY_MORE: 'QUIZ_RELY_MORE',
  QUIZ_TRANSITION_HEAD_HEART: 'QUIZ_TRANSITION_HEAD_HEART',
  QUIZ_TRANSITION_FINAL_TOUCHES: 'QUIZ_TRANSITION_FINAL_TOUCHES',
  QUIZ_RELATIONSHIP_STATUS: 'QUIZ_RELATIONSHIP_STATUS',
  QUIZ_ADVISOR_AREAS: 'QUIZ_ADVISOR_AREAS',
  QUIZ_ADVISOR_COMMUNICATE: 'QUIZ_ADVISOR_COMMUNICATE',
  QUIZ_ADVISOR_EXPECT: 'QUIZ_ADVISOR_EXPECT',
  QUIZ_ADVISOR_COMFORT: 'QUIZ_ADVISOR_COMFORT',
  QUIZ_FUTURE_GOALS: 'QUIZ_FUTURE_GOALS',
  QUIZ_COLORS: 'QUIZ_COLORS',
  QUIZ_ELEMENT: 'QUIZ_ELEMENT',
  QUIZ_EMAIL: 'QUIZ_EMAIL',
  QUIZ_MAILING: 'QUIZ_MAILING',
  QUIZ_SURVEY: 'QUIZ_SURVEY',
  QUIZ_TRUSTPILOT_REVIEW: 'QUIZ_TRUSTPILOT_REVIEW',
  QUIZ_TRANSITION_MOON_DARK: 'QUIZ_TRANSITION_MOON_DARK',
  QUIZ_TRANSITION_MOON_LIGHT: 'QUIZ_TRANSITION_MOON_LIGHT',
  QUIZ_TRANSITION_HAND_DARK: 'QUIZ_TRANSITION_HAND_DARK',
  QUIZ_TRANSITION_HAND_LIGHT: 'QUIZ_TRANSITION_HAND_LIGHT',
  QUIZ_TRANSITION_CARDS_SAY: 'QUIZ_TRANSITION_CARDS_SAY',
  QUIZ_TRANSITION_ANIMATION_TAROT: 'QUIZ_TRANSITION_ANIMATION_TAROT',
  QUIZ_ASTROLOGER_MINUTES: 'QUIZ_ASTROLOGER_MINUTES',
  QUIZ_ASTROLOGER_MINUTES_POPUP: 'QUIZ_ASTROLOGER_MINUTES_POPUP',
  QUIZ_SELECT_PLANS_BY_TRIAL: 'QUIZ_SELECT_PLANS_BY_TRIAL',
  QUIZ_TRANSITION_PARTNERS_RESEARCH: 'QUIZ_TRANSITION_PARTNERS_RESEARCH',
  QUIZ_TRANSITION_PARTNERS_RESULT: 'QUIZ_TRANSITION_PARTNERS_RESULT',
  QUIZ_TRANSITION_TARO_PALM_READING: 'QUIZ_TRANSITION_TARO_PALM_READING',
  QUIZ_FACE_READING: 'QUIZ_FACE_READING',
  QUIZ_GIFT_BOX: 'QUIZ_GIFT_BOX',
  QUIZ_MAP_LOADER: 'QUIZ_MAP_LOADER',
  QUIZ_TRANSITION_LOCATIONS_FOR_LIFE: 'QUIZ_TRANSITION_LOCATIONS_FOR_LIFE',
  QUIZ_TRANSITION_MAP_HAND: 'QUIZ_TRANSITION_MAP_HAND',

  QUIZ_TAROT_SEPARATE_CARDS_TRANS: 'QUIZ_TAROT_SEPARATE_CARDS_TRANS',
  QUIZ_TAROT_CARDS_TOGETHER_TRANS: 'QUIZ_TAROT_CARDS_TOGETHER_TRANS',

  //ASTROCARTOGRAPHY
  QUIZ_ASTROCARTOGRAPHY_WELCOME: 'QUIZ_ASTROCARTOGRAPHY_WELCOME',

  QUIZ_ASTROCARTOGRAPHY_INTEREST_PLACES: 'QUIZ_ASTROCARTOGRAPHY_INTEREST_PLACES',
  QUIZ_ASTROCARTOGRAPHY_ENERGY_PLACES: 'QUIZ_ASTROCARTOGRAPHY_ENERGY_PLACES',
  QUIZ_ASTROCARTOGRAPHY_CHALLENGES_PLACES: 'QUIZ_ASTROCARTOGRAPHY_CHALLENGES_PLACES',
  QUIZ_ASTROCARTOGRAPHY_SLIDER: 'QUIZ_ASTROCARTOGRAPHY_SLIDER',
  QUIZ_ASTROCARTOGRAPHY_MAP_LOADER: 'QUIZ_ASTROCARTOGRAPHY_MAP_LOADER',

  QUIZ_TRANSITION_ASTROCARTOGRAPHY_LINES: 'QUIZ_TRANSITION_ASTROCARTOGRAPHY_LINES',

  // WITCHES
  QUIZ_WITCHES_WELCOME: 'QUIZ_WITCHES_WELCOME',

  QUIZ_TRANSITION_PALM_WITCH: 'QUIZ_TRANSITION_PALM_WITCH',
  QUIZ_TRANSITION_WITCHES_PROGRESS_FIRST: 'QUIZ_TRANSITION_WITCHES_PROGRESS_FIRST',
  QUIZ_TRANSITION_WITCHES_PROGRESS_SECOND: 'QUIZ_TRANSITION_WITCHES_PROGRESS_SECOND',
  QUIZ_TRANSITION_WITCHES_PROGRESS_THIRD: 'QUIZ_TRANSITION_WITCHES_PROGRESS_THIRD',
  QUIZ_TRANSITION_WITCHES_HIDDEN_POWERS: 'QUIZ_TRANSITION_WITCHES_HIDDEN_POWERS',
  QUIZ_TRANSITION_WITCHES_BORN: 'QUIZ_TRANSITION_WITCHES_BORN',
  QUIZ_TRANSITION_WITCHES_TYPE: 'QUIZ_TRANSITION_WITCHES_TYPE',

  QUIZ_WITCHES_PERSONAL_INSIGHT: 'QUIZ_WITCHES_PERSONAL_INSIGHT',
  QUIZ_WITCHES_GUT_FEELING: 'QUIZ_WITCHES_GUT_FEELING',
  QUIZ_WITCHES_FEEL_IN_CROWDS: 'QUIZ_WITCHES_FEEL_IN_CROWDS',
  QUIZ_WITCHES_FEEL_IN_NATURE: 'QUIZ_WITCHES_FEEL_IN_NATURE',
  QUIZ_WITCHES_UNDERSTAND_SYMBOLS: 'QUIZ_WITCHES_UNDERSTAND_SYMBOLS',
  QUIZ_WITCHES_DEPENDING_ON_MOON: 'QUIZ_WITCHES_DEPENDING_ON_MOON'
} as const;

export const QUIZ_NAMES = {
  ...QUIZ_NAMES_GENERAL,
}


type QuizNameGeneralKeys = keyof typeof QUIZ_NAMES_GENERAL;
export type QuizNamesGeneralValues = typeof QUIZ_NAMES_GENERAL[QuizNameGeneralKeys];


export type QuizNameKeys = QuizNameGeneralKeys;
export type QuizNamesValues = Array<QuizNamesGeneralValues>;
