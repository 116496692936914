import { createAsyncThunk } from '@reduxjs/toolkit';
import _sample from 'lodash/sample'

import Attribution from '@web-solutions/module-attribution';
import Analytics from '@web-solutions/module-analytics';

import { init as initAccountManagment } from '@web-solutions/account-management/store/actions'

import { applyTheme } from '@web-solutions/core/utils/theme';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { init as initRemoteConfig } from 'core/store/remote-config/actions';
import { checkActiveSubscription } from 'core/store/billing/actions';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { setTheme } from 'core/utils/images';

import { INITIAL_CONFIG, } from 'src/constants/remote-config';

import { getAstrologersMinutesBalance, getAstrologersData } from 'src/api/astrology';
import { QUIZ_NAMES } from 'src/constants/screens';

import { init as initRouting, } from '../routing/actions';
import { auth, setAstrologerTimeBalance } from '../profile/actions';
import { setChatbotData } from '../chat/actions';
import { selectFlow } from '../routing/selectors';
import { RootState } from '../types';

import { isAsb, validateRemoteConfig } from './utils';

import { getMagnusToken } from './utils';
import { selectAppLink } from './selectors';

import { appSlice } from ".";

export const { setLoaded, setPending, setPaymentProject } = appSlice.actions;

export const load = createAsyncThunk(
  `${appSlice.name}/load`,
  async (_, { dispatch, getState }) => {
    try {
      await dispatch(auth());
      await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG, {
        validate: validateRemoteConfig,
        storageKey: isAsb() ? 'ASB' : '',
      }));

      const linkAuthResult = await dispatch(initAccountManagment()).unwrap();

      const state = getState() as any;

      const remoteConfig = remoteConfigSelector(state);

      if (remoteConfig.theme) {
        applyTheme(remoteConfig.theme);
        if (remoteConfig.theme.mode) {
          setTheme(remoteConfig.theme.mode);
          Analytics.setUserProperty('theme', remoteConfig.theme.mode);
        }
      }

      await dispatch(checkActiveSubscription({ actualized: isAsb() }));
      await dispatch(initRouting({ linkAuthResult }));

      if (!isAsb()) {
        const res = await getAstrologersMinutesBalance();
        dispatch(setAstrologerTimeBalance(res));
      }

      const flow = selectFlow(state)

      if ((flow.includes(QUIZ_NAMES.CHAT_FLOW) || flow.includes(QUIZ_NAMES.CHAT_FLOW_BRANCHING)) && !state?.chat?.chatbotData) {
        const astrologers = await getAstrologersData() as unknown as Record<string, string>[]

        if (astrologers?.length) {
          const { astrologer_id, name, photo } = _sample(astrologers) || {}

          const apiRootURL = process.env.REACT_APP_API_ROOT_URL

          dispatch(setChatbotData({
            id: astrologer_id,
            name,
            avatarLink: photo.includes(apiRootURL!) ? photo : `${apiRootURL}${photo}`
          }))

          Analytics.trackEvent('CHATBOT_SAVE', EVENT_ACTION.SUCCESS, { chatbotId: astrologer_id });
        }
      }

      const {
        remoteConfig: {
          linkTarget,
        },
        chat: {
          chatbotData
        }
      } = getState() as any;

      if ((flow.includes(QUIZ_NAMES.CHAT_FLOW) || flow.includes(QUIZ_NAMES.CHAT_FLOW_BRANCHING)) && chatbotData) {
        Attribution.setURLBaseParams({ urlExtraPath: 'chat', query: { advisor_id: chatbotData.id } });
      }

      Attribution.setRemoteConfigTarget(linkTarget);
      Analytics.setUserProperty('target', linkTarget);
    } catch (error) {
      console.warn('[ERROR INIT APP]', error);
    } finally {
      dispatch(setLoaded(true));
    }
  }
);

export const downloadApp = createAsyncThunk(
  `${appSlice.name}/downloadApp`,
  async (_, { getState }) => {
    const appLink = selectAppLink(getState() as RootState);

    setTimeout(() => window.location.href = appLink, 100)
  }
);