import dayjs from 'dayjs';
import _reduce from 'lodash/reduce'

export function birthDate2Age(date: string) {
  const userDate = dayjs(date);
  const currentDate = dayjs();
  return currentDate.diff(userDate, 'year').toString();
}

export const profileParamsMapper = (params: Record<string, unknown>) => _reduce(params, (acc, cur, key) => {
  if (cur) {
    acc = {
      params: { ...acc.params, [key]: cur }
    }
  }

  return acc
}, { params: {} })